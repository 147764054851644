import { api } from '@moveup/app-core';

const store = {
	state: {
		isShowingTestPatients: false,
	},
	mutations: {
		setIsShowingTestPatients(state, value) {
			state.isShowingTestPatients = value;
		}
	},
	actions: {
		// APP-SPECIFIC CALLS TO THE GATEWAY
		setIsShowingTestPatients({ commit }, value) {
			commit('setIsShowingTestPatients', value);
		},

		getPatients({ state }, params) {
			if (!params) params = {};
			params.isShowingTestPatients = state.isShowingTestPatients || false;
			return api.get('patients', params);
		},

		deletePatients(_, params) {
			return api.del('patients', params);
		},

		countPatients(_, params) {
			return api.get('patients/count', params);
		},

		createRealPatient(_, params) {
			return api.post('patients', params);
		},

		updateRealPatient(_, { id, ...params }) {
			return api.put(`patients/${id}`, params);
		},

		getPatient(_, id) {
			return api.get(`patients/${id}`);
		},

		addFlags(_, patientIds) {
			return api.put('patients/flag', patientIds);
		},

		deleteFlags(_, patientIds) {
			return api.del('patients/flag', patientIds);
		},

		updateCouple(_, { id: id, ...params }) {
			console.log('params:', params);
			return api.put(`couples/${id}`, params);
		},

		removeCouple(_, id) {
			return api.del(`couples/${id}`);
		},

		createCouple(_, params) {
			return api.post(`couples`, params);
		},

		getSurgeons(_, params) {
			if (!params) params = { sortBy: 'profile.firstname|asc' };
			return api.get('practitioners', params);
		},

		getPractitioners(_, params) {
			if (!params) params = { sortBy: 'profile.firstname|asc' };
			return api.get('practitioners', params);
		},

		getAllPractitioners(_, params = {}) {
			if (!params.sortBy) params.sortBy = 'profile.firstname|asc';
			return api.get('practitioners', params);
		},

		getPractitioner(_, id) {
			return api.get(`practitioners/${id}`);
		},

		getOrganization(_, id) {
			return api.get(`organizations/${id}`);
		},

		getOrganizations(_, params) {
			if (!params) params = { sortBy: 'name|asc' };
			return api.get('organizations', params);
		},

		getInterventions(_, params) {
			return api.get('activitydefinitions', params);
		},

		getIntervention(_, id) {
			return api.get(`activitydefinitions/${id}`);
		},

		getTreatment(_, id) {
			return api.get(`treatments/${id}`);
		},

		getTreatments(_, params) {
			if (!params) params = { sortBy: 'treatmentType.asc,type.asc' };
			return api.get('treatments', params);
		},

		getMailPreview(_, params) {
			return api.post('mails/created/preview', params);
		},

		getEmailTemplates(_, params) {
			return api.get('mails', params);
		},

		sendMailTemplate(_, params) {
			return api.post('mail', params);
		},

		getCarePlans(_, params) {
			return api.get('careplans', params);
		},

		getCareTeams(_, params) {
			return api.get('careteams', params);
		},

		getProcedures(_, { patient, perPage }) {
			let params = {
				subject: patient,
				perPage,
			};
			return api.get('procedures', params);
		},

		isEmailTaken(_, { emailAddress, id }) {
			if(id){
				return api.get(`patients/email/${emailAddress}/${id}`);
			}else{
				return api.get(`patients/email/${emailAddress}`);
			}
		},

		isCellPhoneNumberTaken(_, { cellphoneNumber, id }) {
			if(id) {
				return api.get(`patients/phone/${cellphoneNumber}/${id}`);
			}else{
				return api.get(`patients/phone/${cellphoneNumber}`);
			}
		},

		getLanguages() {
			return api.get(`languages`);
		},
		getMessageTemplates(_, query){
			return api.get(`messages`, query);
		},
		sendMessageTemplate(_, params) {
			return api.post('messages', params);
		},
		getConsents(_, id) {
			return api.get(`patients/${id}/consents`);
		},
		signConsent(_, { id, consentId, signedOptions }) {
			return api.post(`patients/${id}/consent/${consentId}`, signedOptions);
		}
	},
};

export default store;
